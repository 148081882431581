import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'config/redux/store';
import { IntakeFormPage } from 'pages/IntakeFormPage/IntakeFormPage';
import { createOldWayIntake, getOldWayIntakeFields } from 'services/api/mos-configurator/MosConfiguratorApi';
import { HOME_ROUTE } from 'services/constants/routes';
import {
	selectIntakeForm,
	selectIntakeFormError,
	selectIntakeFormSchema,
	selectIntakeFormSteps,
	selectIsLoadingIntakeFormSchema,
	selectJiraLink,
} from 'services/store/intakeForm/IntakeFormSelectors';
import {
	clearIntakeFormError,
	clearIntakeFormSteps,
	clearJiraLink,
	setIntakeFormSteps,
	setPresetFields,
} from 'services/store/intakeForm/IntakeFormSlice';
import { selectUser } from 'services/store/user/UserSelectors';
import { STEP_MODES } from 'services/types/common';
import { useSnack } from 'utils/hooks/snack';
import {
	getIsValidFormSteps,
	openUrlInNewTab,
	transformSchemaToSteps,
	updateStepsWithDependentRequired,
} from 'utils/utils';

export const IntakeFormPageContainer = () => {
	const snack = useSnack();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const user = useSelector(selectUser);
	const jiraLink = useSelector(selectJiraLink);
	const intakeFormError = useSelector(selectIntakeFormError);
	const intakeFormSchema = useSelector(selectIntakeFormSchema);
	const intakeFormSteps = useSelector(selectIntakeFormSteps);
	const isLoadingIntakeForm = useSelector(selectIsLoadingIntakeFormSchema);
	const intakeForm = useSelector(selectIntakeForm);

	useEffect(() => {
		const { email = '' } = user;
		if (email && Array.isArray(intakeFormSteps) && intakeFormSteps.length) {
			dispatch(setPresetFields({ email }));
		}
	}, [user, intakeFormSteps, dispatch]);

	useEffect(() => {
		if (intakeFormSchema) {
			const steps = transformSchemaToSteps(intakeFormSchema);
			const stepsWithDependentRequired = updateStepsWithDependentRequired(steps, intakeFormSchema);
			const stepsWithUncompletedState = stepsWithDependentRequired.map((step, index) => ({
				...step,
				mode: index === 0 ? STEP_MODES.EDIT : STEP_MODES.UNCOMPLETED,
			}));

			dispatch(setIntakeFormSteps({ steps: stepsWithUncompletedState }));
		} else {
			dispatch(getOldWayIntakeFields());
		}
	}, [intakeFormSchema, dispatch]);

	useEffect(() => {
		if (intakeFormError) {
			snack.error(intakeFormError);
			dispatch(clearIntakeFormError());
		}
	}, [snack, intakeFormError, dispatch]);

	useEffect(() => {
		const onDone = () => {
			dispatch(clearJiraLink());
			dispatch(clearIntakeFormSteps());
		};

		if (jiraLink) {
			navigate(HOME_ROUTE);
			openUrlInNewTab(jiraLink, onDone);
		}
	}, [jiraLink, navigate, dispatch]);

	const handlecreateOldWayIntake = useCallback(() => {
		const isValidIntakeForm = getIsValidFormSteps(intakeFormSteps, (steps) => {
			dispatch(setIntakeFormSteps({ steps }));
		});

		if (!isValidIntakeForm) {
			return;
		}

		dispatch(
			createOldWayIntake({
				form: intakeForm,
			})
		).then(() => navigate(HOME_ROUTE));
	}, [intakeFormSteps, intakeForm, dispatch, navigate]);

	return <IntakeFormPage isLoading={isLoadingIntakeForm} steps={intakeFormSteps} onSubmit={handlecreateOldWayIntake} />;
};
